<template>
  <sidebar-content
    hide-submit
    :open="openModal"
    has-menu
    :title="'Cadastrar um novo usuário ' + $t('group.name.' + group)"
    @close="closeModal">
    <v-container
      class="new-user pt-0"
      grid-list-lg>
      <loading
        v-if="loading > 0"
        class="absolute-center" />
      <v-form
        v-else
        ref="form"
        v-model="valid"
        lazy-validation>
        <form-user
          :items="account"
          class="px-0 py-0"
          submit-label="Cadastrar usuário"
          @submit="create" />
        <!-- <v-container v-else class="px-0 py-0">
          <v-layout row wrap>
            <v-flex xs12 sm4>
              <v-text-field
                label="CPF"
                v-model="account.document"
                :rules="cpfRules"
                v-mask="mask.cpf"
                data-cy="userCpf"
                required
                ref="document"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm4>
              <v-text-field
                label="Data de nascimento"
                v-model="account.birthDate"
                :rules="dateRules"
                v-mask="mask.date"
                masked
                required
                data-cy="userBirthDate"
            ></v-text-field>
            </v-flex>
            <v-flex xs12 sm4>
              <field-phone @change="changeNewPhone" :options="account.phones" dataCy="userPhone" label="Telefone" />
            </v-flex>
            <v-flex xs12 sm4>
              <v-text-field
                label="Nome completo"
                v-model="account.name"
                :rules="requiredRules"
                required
                data-cy="userName"
                >
            </v-text-field>
            </v-flex>
            <v-flex xs12 sm4>
              <v-text-field
                label="Email"
                v-model="account.email"
                type="email"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm4>

            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex sm4 xs12>
              <div class="input-group input-group--focused input-group--text-field input-group--multi-line primary--text" :class="{'input-group--error error--text': !hasValidCourse}">
                <label>Certificado do Curso</label>
                <div class="input-group__details input-group__course">
                  <vue-dropzone ref="dropDocuments" id="drop-documents" :options="dropzoneOptions" @vdropzone-sending="sendingFile" @vdropzone-success="uploadSuccess"></vue-dropzone>
                  <div class="input-group__messages input-group__error" v-if="!hasValidCourse">
                    Campo obrigatório
                  </div>
                </div>
              </div>
            </v-flex>
            <v-flex sm8 xs12>
              <v-text-field
                label="Mini bio"
                multi-line
                :rules="requiredRules"
              >
              </v-text-field>
            </v-flex>
          </v-layout>
          <div class="pb-2 actions-fixed text-right">
            <v-btn
              @click="createMediator"
              :disabled="!valid"
              color="accent"
              data-cy="mediatorCreate"
            >
              Cadastrar mediador
            </v-btn>
          </div>
        </v-container> -->
      </v-form>
    </v-container>
  </sidebar-content>
</template>

<script>
  import SidebarContent from '@/components/ui/SidebarContent'
  import validate from '@/mixins/validate'
  import masks from '@/mixins/masks'
  import FormUser from '@/components/ui/forms/FormUser'
  import Loading from '@/components/ui/Loading'

  export default {
    name: 'Account',
    components: {
      SidebarContent,
      FormUser,
      Loading
    },
    mixins: [ validate, masks ],
    data: () => ({
      loading: 0,
      loadingGroups: 0,
      valid: true,
      openModal: false,
      groups: [
        { name: 'administrator' },
        { name: 'negotiator' },
        { name: 'supervisor' }
      ],
      group: '',
      account: {
        groups: [],
        document: '',
        email: '',
        name: '',
        birthDate: '',
        phones: []
      }
    }),
    beforeMount () {
      this.group = this.$route.params.group
      this.account.groups = [this.group]
      this.showModal()
    },
    beforeDestroy () {
      this.closeModal()
    },
    methods: {
      showModal () {
        this.openModal = true
      },
      closeModal () {
        this.openModal = false
        this.$nextTick(() => {
          let group = this.$route.params.group
          this.$router.push({ name: 'Users', params: { group } })
        })
      },
      create (data) {
        if (this.$refs.form.validate()) {
          this.loading = 1
          this.$store.commit('app/setLoading', true)
          data.groups = this.account.groups
          let account = data

          this
            .$store
            .dispatch('user/create', account)
            .then(this._success)
            .catch(this._failure)
        }
      },
      // getGroups (groups) {
      //   this.account.groups = groups.map(group => ({name: group}))
      //   console.log(this.account, groups)

      //   let hasMediator = groups.filter(group => group === 'negotiator')
      //   this.hasMediator = hasMediator.length === 1
      // },
      _success () {
        this.loading = 0
        this.$store.commit('app/setLoading', false)
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Conta criada com sucesso'})
        const group = this.$route.params.group
        this.$router.push({ name: 'Users', params: { group } })
      },
      _failure (err) {
        this.loading = 0
        this.$store.commit('app/setLoading', false)
        this.$nextTick(() => {
          this.$root.$emit('snackbar', {show: true, color: 'error', text: 'houve um problema'})
        })
      }
    }
  }
</script>
