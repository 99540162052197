<template>
  <sidebar-content
    class="new-mediator"
    :open="openModal"
    has-menu
    hide-submit
    title="Cadastrar um mediador"
    @close="closeModal">
    <v-stepper
      v-model="step"
      v-resize="onResize"
      class="elevation-0"
      vertical
      :style="setHeightScroll">
      <v-stepper-step
        step="1"
        :complete="step > 1">
        <h2 class="title">
          Dados do Mediador
        </h2>
      </v-stepper-step>
      <v-stepper-content step="1">
        <v-form
          ref="formStep1"
          v-model="validStep1"
          lazy-validation />
        <v-container
          class="pt-0"
          grid-list-lg>
          <v-form
            ref="formStep1"
            v-model="validStep1"
            lazy-validation>
            <!-- <v-layout row wrap>
              <v-flex xs12 sm6>
                <v-text-field
                  label="CPF"
                  v-model="account.document"
                  :rules="cpfRules"
                  v-mask="mask.cpf"
                  data-cy="userCpf"
                  required
                  ref="document"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6>
                 <date-picker label="Data de nascimento" :date="account.birthDate" @change="saveBirthDate"></date-picker>
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field
                  label="Nome completo"
                  v-model="account.name"
                  :rules="requiredRules"
                  required
                  data-cy="userName"
                  >
              </v-text-field>
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field
                  label="Email"
                  :rules="emailRules"
                  v-model="account.email"
                  required
                  type="email"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field
                  label="Inscrição no CNJ"
                  v-model="account.cnj"
                  :rules="requiredRules"
                  required
                  type="number"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6>
                <field-phone @change="changeNewPhone" :options="account.phones" dataCy="userPhone" label="Telefone" />
              </v-flex>
            </v-layout> -->

            <h2 class="subtitle-1 darkGray--text mb-3">
              <v-icon
                size="22px"
                class="icon-align-text mr-1"
                color="darkGray">
                {{ $root.icons.layout.user }}
              </v-icon>
              Dados pessoais
            </h2>
            <form-user
              :items="recipient"
              required-phone
              hide-submit
              email-actions
              @blur="updateDataPersonal" />

            <h2 class="subtitle-1 mt-2 darkGray--text">
              <v-icon
                class="icon-align-text mr-1"
                size="22px"
                color="darkGray">
                {{ $root.icons.layout.place }}
              </v-icon>
              Dados de endereço
            </h2>
            <form-address
              :items="addresses"
              hide-submit
              @blur="updateDataAddress" />

            <!--  <div class="actions-step-fixed">
              <v-btn
                title="Voltar ao passo anterior"
                depressed
                text
                class="textColor--text"
                @click.native="prevStep">
                voltar
              </v-btn>
              <v-btn
                @click="createCase"
                title="Criar um caso"
                :disabled="!validStep2"
                color="accent"
                >
                Cadastrar caso
              </v-btn>
            </div>

 -->
            <div class="actions-step-fixed">
              <v-btn
                title="Ir para o próximo passo"
                color="accent"
                :disabled="!validStep1"
                depressed
                @click="submitStep1">
                Avançar
              </v-btn>
            </div>
          </v-form>
        </v-container>
      </v-stepper-content>

      <v-stepper-step
        step="2"
        :complete="step > 2">
        <h2 class="title">
          Dados Profissionais
        </h2>
      </v-stepper-step>
      <v-stepper-content step="2">
        <v-container
          class="pt-0"
          grid-list-lg>
          <v-form
            ref="formStep2"
            v-model="validStep2"
            lazy-validation>
            <v-layout
              row
              wrap>
              <v-flex
                sm4
                xs12>
                <v-select
                  v-model="regionSelected"
                  label="Região de atuação"
                  :items="regionAreaList"
                  :rules="requiredRules"
                  required />
              </v-flex>
              <v-flex
                sm4
                xs12>
                <v-select
                  v-model="atuationSelected"
                  item-text="label"
                  item-value="value"
                  label="Especialidade"
                  :items="atuationAreaList"
                  :rules="requiredRules"
                  required />
              </v-flex>
              <v-flex
                sm4
                xs12>
                <v-text-field
                  v-model="cnjNumber"
                  label="Nº da Inscrição CNJ"
                  type="number"
                  :rules="requiredRules"
                  required />
              </v-flex>
              <v-flex
                sm4
                xs12>
                <div
                  class="input-group input-group--focused input-group--text-field input-group--multi-line primary--text"
                  :class="{'input-group--error error--text': !hasValidImage}">
                  <label>Foto</label>
                  <div class="input-group__details input-group__course">
                    <vue-dropzone
                      id="drop-documents"
                      ref="dropImage"
                      :options="dropzoneOptions"
                      @vdropzone-sending="sendingImage"
                      @vdropzone-success="uploadImageSuccess" />
                    <div
                      v-if="!hasValidImage"
                      class="input-group__messages input-group__error">
                      Campo obrigatório
                    </div>
                  </div>
                </div>
              </v-flex>
              <v-flex
                sm4
                xs12>
                <div
                  class="input-group input-group--focused input-group--text-field input-group--multi-line primary--text"
                  :class="{'input-group--error error--text': !hasValidCourse}">
                  <label>Certificado do Curso</label>
                  <div class="input-group__details input-group__course">
                    <vue-dropzone
                      id="drop-documents"
                      ref="dropCourse"
                      :options="dropzoneOptions"
                      @vdropzone-sending="sendingCourse"
                      @vdropzone-success="uploadCourseSuccess" />
                    <div
                      v-if="!hasValidCourse"
                      class="input-group__messages input-group__error">
                      Campo obrigatório
                    </div>
                  </div>
                </div>
              </v-flex>
              <v-flex
                sm4
                xs12>
                <div
                  class="input-group input-group--focused input-group--text-field input-group--multi-line primary--text"
                  :class="{'input-group--error error--text': !hasValidDocuments}">
                  <label>RG e CPF ou CNH</label>
                  <div class="input-group__details input-group__course">
                    <vue-dropzone
                      id="drop-documents"
                      ref="dropDocument"
                      :options="dropzoneOptions"
                      @vdropzone-sending="sendingDocument"
                      @vdropzone-success="uploadDocumentSuccess" />
                    <div
                      v-if="!hasValidDocuments"
                      class="input-group__messages input-group__error">
                      Campo obrigatório
                    </div>
                  </div>
                </div>
              </v-flex>
              <v-flex
                sm12
                xs12>
                <v-text-field
                  label="Minicurrículo"
                  :counter="500"
                  :rules="maxLength500"
                  multi-line
                  required />
              </v-flex>
            </v-layout>
            <div class="actions-step-fixed">
              <v-btn
                class="textColor--text"
                title="Voltar ao passo anterior"
                depressed
                text
                @click.native="prevStep">
                voltar
              </v-btn>
              <v-btn
                color="accent"
                title="Ir para o próximo passo"
                :disabled="!validStep2"
                depressed
                @click="submitStep2">
                Avançar
              </v-btn>
            </div>
          </v-form>
        </v-container>
      </v-stepper-content>
      <v-stepper-step
        step="3"
        :complete="step > 3">
        <h2 class="title">
          Dados de Recebimento
        </h2>
      </v-stepper-step>
      <v-stepper-content step="3">
        <v-container
          class="pt-0"
          grid-list-lg>
          <p class="textColor--text">
            Os campos abaixo não são obrigatórios
          </p>
          <v-layout
            row
            wrap>
            <v-flex
              xs12
              sm6>
              <v-text-field
                ref="focusCnpj"
                v-model="company.cnpj"
                v-mask="mask.cnpj"
                label="CNPJ"
                data-cy="companyCnpj" />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-text-field
                v-model="company.stateRegistration"
                label="Inscrição estadual"
                data-cy="companyStateRegistration"
                type="number" />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-text-field
                v-model="company.municipalRegistration"
                label="Inscrição Municipal"
                data-cy="companyMunicipalRegistration"
                type="number" />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-text-field
                v-model="company.socialReason"
                label="Razão Social"
                data-cy="companySocialReason" />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-text-field
                v-model="company.fantasyName"
                label="Nome Fantasia"
                data-cy="companyFantasyName"
                :rules="requiredRules" />
            </v-flex>
          </v-layout>
          <div class="actions-step-fixed">
            <v-btn
              title="Voltar ao passo anterior"
              class="textColor--text"
              depressed
              text
              @click.native="prevStep">
              voltar
            </v-btn>
            <v-btn
              color="accent"
              title="Ir para o próximo passo"
              :disabled="!validStep3"
              depressed
              @click="submitStep3">
              Avançar
            </v-btn>
          </div>
        </v-container>
      </v-stepper-content>
      <v-stepper-step
        step="4"
        :complete="step > 4">
        <h2 class="title">
          Dados da Conta de Recebimento
        </h2>
      </v-stepper-step>
      <v-stepper-content step="4">
        <v-container
          class="pt-0"
          grid-list-lg>
          <p class="textColor--text">
            Os campos abaixo não são obrigatórios
          </p>
          <v-form
            ref="formStep4"
            v-model="validStep4"
            lazy-validation>
            <form-bank
              class="px-0"
              submit-label="Cadastrar"
              btn-cancel-label="Voltar"
              :items="bank"
              btn-cancel-show
              @submit="submitStep4"
              @cancel="prevStep" />
          </v-form>
        </v-container>
      </v-stepper-content>
    </v-stepper>
    <v-container
      class="new-mediator pt-0"
      grid-list-lg>
      <v-container class="px-0 py-0">
        <!-- <div class="pb-2 actions-fixed text-right">
            <v-btn
              @click="createMediator"
              :disabled="!valid"
              color="accent"
              data-cy="mediatorCreate"
            >
              Cadastrar mediador
            </v-btn>
          </div> -->
      </v-container>
    </v-container>
  </sidebar-content>
</template>

<script>
  import SidebarContent from '@/components/ui/SidebarContent'
  // import SelectGroupsAccount from '@/components/ui/SelectGroupsAccount'
  import validate from '@/mixins/validate'
  import masks from '@/mixins/masks'
  // import { CREATE_ACCOUNT } from '@/graphql/users'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'
  import vueDropzone from 'vue2-dropzone'
  // import FieldPhone from '@/components/ui/FieldPhone'
  // import DatePicker from '@/components/ui/DatePicker'
  import resizing from '@/mixins/resizing'
  import FormAddress from '@/components/ui/forms/FormAddress'
  // import FormCompany from '@/components/ui/forms/FormCompany'
  import FormBank from '@/components/ui/forms/FormBank'
  import FormUser from '@/components/ui/forms/FormUser'
  import { states } from '@/utils'
  import { mapGetters } from 'vuex'

  export default {
    name: 'NewMediator',
    components: {
      SidebarContent,
      // SelectGroupsAccount,
      vueDropzone,
      // FieldPhone,
      // DatePicker,
      FormAddress,
      FormUser,
      // FormCompany,
      FormBank
    },
    mixins: [ validate, masks, resizing ],
    data: () => ({
      step: 1,
      validStep1: true,
      validStep2: true,
      validStep3: true,
      validStep4: true,
      loadingGroups: 0,
      openModal: false,
      regionSelected: '',
      atuationSelected: '',
      cnjNumber: '',
      groups: [],
      atuationAreaList: [
        { value: 'civel', label: 'Cível', disabled: false },
        { value: 'family', label: 'Família', disabled: true },
        { value: 'enterprise', label: 'Empresarial', disabled: true }
      ],
      regionAreaList: states,
      company: {
        fantasyName: '',
        cnpj: '',
        website: '',
        stateRegistration: '',
        municipalRegistration: '',
        socialReason: '',
        logo: '',
        email: ''
      },
      bank: {
        bank: '',
        agency: '',
        account: '',
        name: ''
      },
      account: {
        groups: [{ name: 'mediator' }],
        document: '',
        email: '',
        name: '',
        birthDate: '',
        cnj: '',
        phones: [],
        course: null,
        image: null,
        documents: null
      },
      recipient: {
        type: 'pf',
        document: '',
        birthDate: '',
        email: '',
        name: '',
        phones: []
      },
      addresses: {
        type: '',
        address: '',
        complement: '',
        number: '',
        neighborhood: '',
        city: '',
        uf: '',
        zipCode: ''
      },
      newMediator: {
        eventId: '',
        tickets: [],
        recipient: {
          type: '',
          document: '',
          birthDate: '',
          email: '',
          name: '',
          phones: [],
          address: {}
        }
      },
      hasMediator: false,
      dropzoneOptions: {
        dictDefaultMessage: '<i aria-hidden="true" class="icon mediumGray--text material-icons" style="font-size: 50px;">file_upload</i><br>Arraste o arquivo <br>ou clique nessa área'
      },
      hasValidCourse: true,
      hasValidImage: true,
      hasValidDocuments: true
    }),
    computed: {
      ...mapGetters('global', ['getHostUpload'])
    },
    // TODO Listar Grupos
    watch: {
      step (val) {
        switch (val) {
        case 3: {
          this.focusOnCnpj()
          break
        }
        }
      }
    },
    mounted () {
      this.showModal()
    },
    beforeDestroy () {
      this.closeModal()
    },
    beforeMount () {
      this.dropzoneOptions.url = this.getHostUpload
      this.dropzoneOptions.headers = { 'Authorization': `Bearer ${this.$store.state.user.user.token}` }
    },
    methods: {
      showModal () {
        this.openModal = true
      },
      closeModal () {
        this.openModal = false
        this.$nextTick(() => {
          let group = this.$route.params.group
          this.$router.push({ name: 'Users', params: { group } })
        })
      },
      getGroups (groups) {
        this.account.groups = groups.map(group => ({name: group}))

        let hasMediator = groups.filter(group => group === 'negotiator')
        this.hasMediator = hasMediator.length === 1
      },
      _failure () {
        this.$nextTick(() => {
          this.$root.$emit('snackbar', {show: true, color: 'error', text: 'houve um problema'})
        })
      },
      sendingCourse (file, xhr, formData) {
        console.log('sendingCourse', file)
        this.account.course = file
        this._checkFileCourse()
        // formData.append('resource', `Case:${this.$route.params.caseId}`)
      },
      uploadCourseSuccess (file) {
        console.log('uploadCourseSuccess', file)
        this.$refs.dropCourse.removeFile(file)
      },
      sendingImage (file, xhr, formData) {
        console.log('sendingImage', file)
        this.account.image = file
        this._checkFileCourse()
        // formData.append('resource', `Case:${this.$route.params.caseId}`)
      },
      uploadImageSuccess (file) {
        console.log('uploadImageSuccess', file)
        this.$refs.dropImage.removeFile(file)
      },
      sendingDocument (file, xhr, formData) {
        console.log('sendingImage', file)
        this.account.documents = file
        this._checkFileDocuments()
        // formData.append('resource', `Case:${this.$route.params.caseId}`)
      },
      uploadDocumentSuccess (file) {
        console.log('uploadImageSuccess', file)
        this.$refs.dropDocument.removeFile(file)
      },
      changeNewPhone (selectedPhone, index) {
        let objectPhone = selectedPhone[0] // only phone
        this.account.phones.splice(index, 1, objectPhone)
      },
      _checkFileCourse () {
        this.hasValidCourse = this.validStep2 = this.account.course !== null
      },
      _checkFileImage () {
        this.hasValidImage = this.validStep2 = this.account.image !== null
      },
      _checkFileDocuments () {
        this.hasValidDocuments = this.validStep2 = this.account.documents !== null
      },
      saveBirthDate (date) {
        this.account.birthDate = date
      },
      prevStep () {
        this.step = this.step - 1
      },
      submitStep1 () {
        if (this.$refs.formStep1.validate()) {
          console.log('step 1', this.account)
          this.step = 2
        }
      },
      submitStep2 () {
        this._checkFileCourse()
        this._checkFileImage()
        this._checkFileDocuments()
        // falta fazer o check do documentos
        // console.log('checked others', this.hasValidCourse, this.hasValidImage)
        if (this.$refs.formStep2.validate() && this.hasValidImage && this.hasValidCourse) {
          this.step = 3
        }
      },
      submitStep3 () {
        // if (this.$refs.formStep3.validate()) {

        // }
        console.log('submitStep3')
        this.step = 4
      },
      submitStep4 () {
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Mediador criado com sucesso'})
        // if (this.$refs.formStep4.validate()) {
        //   account.groups = this.account.groups

        //   this.$apollo.mutate({
        //     mutation: CREATE_ACCOUNT,
        //     variables: { account }
        //   }).then((result) => {
        //     if (result.data.createAccount.errors) {
        //       this._failure()
        //     } else {
        //       this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Conta criada com sucesso'})
        //       let group = this.$route.params.group
        //       this.$router.push({ name: 'Users', params: { group } })
        //     }
        //   }).catch((error) => {
        //     console.error('', error)
        //     this._failure()
        //   })
        // }
      },
      updateDataPersonal (data) {
        this.newMediator.recipient = data
      },
      updateDataAddress (data) {
        this.newMediator.recipient.address = data
      },
      focusOnCnpj () {
        this.$refs.focusCnpj.focus()
      }
    }
  }
</script>

<style lang="sass">
  .new-mediator
    .v-input-group__details.v-input-group__course
      // display: flex
      flex-direction: column
      width: 100%

      &::after, &::before
        display: none

      // .v-input-group__error
      //   color: $error!important

    .vue-dropzone
      min-height: 50px
      max-height: 100px
      padding: 0 20px
      margin-top: 5px
      width: 100%

      .dz-preview.dz-image-preview
        background: transparent

      .dz-default
        margin: 0
        padding: 5px

      .dz-preview
        margin: 0

        img
          // max-width: 95%
          max-width: 60%

      .dz-success-mark,
      .dz-error-mark
        top: 0

        svg
          width: 30px
          height: 40px
</style>
