<template>
  <v-container grid-list-lg>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation>
      <div
        v-resize="onResize"
        class="height-auto"
        :style="setAutoHeight">
        <v-layout
          row
          wrap>
          <edit-actions
            v-if="actionsEdit"
            :valid="valid"
            :display-mode="isDisplayMode"
            @change="changeDisplay"
            @save="save" />

          <v-flex
            v-if="!hideFields"
            xs12
            sm6>
            <display-field
              v-if="isDisplayMode"
              label="Convenio"
              :value="items.covenant" />
            <v-text-field
              v-else
              v-model="dataItems.covenant"
              label="Convenio"
              data-cy="bankCovenant"
              :disabled="isDisabled" />
          </v-flex>
          <v-flex
            v-if="!hideFields"
            xs12
            sm6>
            <display-field
              v-if="isDisplayMode"
              label="Carteira"
              :value="items.wallet" />
            <v-text-field
              v-else
              v-model="dataItems.wallet"
              label="Carteira"
              data-cy="bankWallet"
              :disabled="isDisabled" />
          </v-flex>
          <v-flex
            v-if="!hideFields"
            xs12
            sm6>
            <display-field
              v-if="isDisplayMode"
              label="Variação Carteira"
              :value="items.walletVariation" />
            <v-text-field
              v-else
              v-model="dataItems.walletVariation"
              label="Variação Carteira"
              data-cy="bankWalletVariation"
              :disabled="isDisabled" />
          </v-flex>
          <v-flex
            xs12
            sm6>
            <display-field
              v-if="isDisplayMode"
              label="Banco"
              :value="items.bank" />
            <v-text-field
              v-else
              v-model="dataItems.bank"
              label="Banco"
              data-cy="bankName"
              :disabled="isDisabled" />
          </v-flex>
          <v-flex
            xs12
            sm6>
            <display-field
              v-if="isDisplayMode"
              label="Agência"
              :value="items.agency" />
            <v-text-field
              v-else
              v-model.trim="dataItems.agency"
              label="Agência"
              data-cy="bankAgency"
              :disabled="isDisabled" />
          </v-flex>
          <v-flex
            xs12
            sm6>
            <display-field
              v-if="isDisplayMode"
              label="Conta"
              :value="items.account" />
            <v-text-field
              v-else
              v-model.trim="dataItems.account"
              label="Conta"
              data-cy="bankAccount"
              :disabled="isDisabled" />
          </v-flex>
          <v-flex
            xs12
            sm6>
            <display-field
              v-if="isDisplayMode"
              label="Nome     "
              :value="items.name" />
            <v-text-field
              v-else
              v-model="dataItems.name"
              label="Nome"
              data-cy="bankFavored"
              :disabled="isDisabled" />
          </v-flex>
        </v-layout>
      </div>

      <div
        class="pb-2 actions-step-fixed"
        :class="`text-${btnPosition}`">
        <v-btn
          v-if="btnCancelShow"
          title="Voltar ao passo anterior"
          class="textColor--text mr-2"
          depressed
          text
          @click.native="cancel">
          {{ btnCancelLabel }}
        </v-btn>
        <v-btn
          v-if="!hideSubmit"
          color="accent"
          data-cy="bankSubmit"
          title="Ir para o próximo passo"
          :disabled="!valid"
          @click="submit">
          {{ submitLabel }}
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
  import formsMixin from '@/mixins/forms'

  export default {
    mixins: [ formsMixin ],
    props: {
      hideFields: {
        type: Boolean,
        default: false
      }
    },
    mounted () {
      this.dataItems = Object.assign({}, this.items)
      this.isDisabled = this.disabled
    }
  }
</script>
