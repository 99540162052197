<template>
  <v-container grid-list-lg>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation>
      <div
        v-resize="onResize"
        class="height-auto"
        :style="setAutoHeight">
        <v-layout
          row
          wrap>
          <edit-actions
            v-if="actionsEdit"
            :valid="valid"
            :display-mode="isDisplayMode"
            @change="changeDisplay"
            @save="save" />
          <v-flex
            xs12
            sm6
            class="forced-flex">
            <display-field
              v-if="isDisplayMode"
              label="CEP"
              :value="dataItems.zipCode" />
            <select-cep
              v-else
              masked
              data-cy="addressZipcode"
              :items="listCeps"
              :empty="empty"
              @change="changeCep"
              @blur="blurField"
              @empty="_resetAddress" />
          </v-flex>
          <!-- </v-layout>
        <v-layout row wrap class="mb-6"> -->
          <v-flex
            xs12
            sm6
            class="forced-flex">
            <display-field
              v-if="isDisplayMode"
              label="Endereço"
              :value="dataItems.address" />
            <v-text-field
              v-else
              v-model="dataItems.address"
              label="Endereço"
              :rules="requiredRules"
              :disabled="isDisabled"
              required
              @blur="blurField" />
          </v-flex>
          <v-flex
            xs12
            sm3
            class="forced-flex">
            <display-field
              v-if="isDisplayMode"
              label="Número"
              :value="dataItems.number" />
            <v-text-field
              v-else
              v-model="dataItems.number"
              label="Número"
              :disabled="isDisabled"
              required
              @blur="blurField" />
          </v-flex>
          <v-flex
            xs12
            sm3
            class="forced-flex">
            <display-field
              v-if="isDisplayMode"
              label="Complemento"
              :value="dataItems.complement" />
            <v-text-field
              v-else
              v-model="dataItems.complement"
              label="Complemento"
              :disabled="isDisabled"
              @blur="blurField" />
          </v-flex>

          <v-flex
            xs12
            sm4
            class="forced-flex">
            <display-field
              v-if="isDisplayMode"
              label="Bairro"
              :value="dataItems.neighborhood" />
            <v-text-field
              v-else
              v-model="dataItems.neighborhood"
              label="Bairro"
              :disabled="isDisabled"
              :rules="requiredRules"
              required
              @blur="blurField" />
          </v-flex>

          <v-flex
            xs12
            sm5
            class="forced-flex">
            <display-field
              v-if="isDisplayMode"
              label="Cidade"
              :value="dataItems.city" />
            <v-text-field
              v-else
              v-model="dataItems.city"
              label="Cidade"
              :rules="requiredRules"
              :disabled="isDisabled"
              required
              @blur="blurField" />
          </v-flex>
          <v-flex
            xs12
            sm3
            class="forced-flex">
            <display-field
              v-if="isDisplayMode"
              label="Estado"
              :value="dataItems.uf" />
            <v-select
              v-else
              v-model="dataItems.uf"
              label="Estado"
              :items="states"
              :rules="multipleRules"
              :disabled="isDisabled"
              required
              @blur="blurField" />
          </v-flex>
          <!-- <v-flex xs12 sm3 v-if="addressActions">
            <v-tooltip top v-if="index > 0">
              <v-btn fab small v-on="on" class="elevation-0" @click="remove(address)">
                <v-icon small dark color="error">{{$root.icons.action.remove}}</v-icon>
              </v-btn>
              <span>Remover endereço</span>
            </v-tooltip>
            <v-tooltip top v-if="showAddAddress(index)">
              <v-btn fab small v-on="on" class="elevation-0" @click="add">
                <v-icon small dark color="success">{{$root.icons.action.add}}</v-icon>
              </v-btn>
              <span>Adicionar endereço</span>
            </v-tooltip>
          </v-flex> -->
        </v-layout>
      </div>

      <div
        class="pb-2 actions-step-fixed"
        :class="`text-${btnPosition}`">
        <v-btn
          v-if="btnCancelShow"
          title="Voltar ao passo anterior"
          class="mr-2 textColor--text"
          depressed
          t-e-x-t
          @click.native="cancel">
          {{ btnCancelLabel }}
        </v-btn>
        <v-btn
          v-if="!hideSubmit"
          data-cy="addressSubmit"
          title="Ir para o próximo passo"
          color="accent"
          :outlined="btnOutline"
          :block="btnBlock"
          :disabled="!valid"
          :loading="isLoading"
          depressed
          @click="submit">
          {{ submitLabel }}
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
  import { states } from '@/utils'
  import formsMixin from '@/mixins/forms'
  import SelectCep from '@/components/ui/SelectCep'
  import { mapGetters } from 'vuex'

  export default {
    components: { SelectCep },
    mixins: [ formsMixin ],
    props: {
      // addressActions: {
      //   type: Boolean,
      //   default: false
      // },
      investigator: {
        type: Array,
        default: () => []
      },
      empty: {
        type: Boolean,
        default: true
      },
      hasInvestigator: {
        type: Boolean,
        default: true
      }
    },
    data: () => ({
      states: states,
      listCeps: []
    }),
    computed: {
      ...mapGetters({
        isLoading: 'app/getLoading'
      })
    },
    watch: {
      items (val) {
        this.dataItems = val
        this.listCeps = [val]
      },
      investigator (addresses) {
        this.listCeps = addresses
      },
      empty (isEmpty) {
        isEmpty ? this._resetAllDataAddress() : this.setListCeps()
      }
    },
    mounted () {
      this.isDisabled = this.disabled
      this.dataItems = Object.assign({}, this.items)
      if (this.hasInvestigator){
        this.listCeps = this.investigator || []
      } else {
        this.listCeps = [this.dataItems]
      }
    },
    methods: {
      // remove (address) {
      //   this.dataItems = this.dataItems.filter(record => record !== address)
      // },
      // add () {
      //   this.dataItems.push({})
      // },
      // showAddAddress (index) {
      //   return this.dataItems.length === index + 1
      // },
      blurField () {
        this.$emit('blur', this.dataItems)
        this.isDisplayMode = false
      },
      changeCep (address) {
        const hasAddress = address.length > 0 && address[0]
        hasAddress ? this._setDataAddress(address) : this._resetAddress()
      },
      _resetAddress () {
        this.dataItems.address = ''
        this.dataItems.number = ''
        this.dataItems.complement = ''
        this.dataItems.neighborhood = ''
        this.dataItems.city = ''
        this.dataItems.uf = ''
        this.dataItems.zipCode = ''
      },
      _setDataAddress (updateAddressess) {
        const updateAddress = Array.isArray(updateAddressess) ? updateAddressess[0] : updateAddressess
        if (typeof updateAddress === 'object') {
          this.$nextTick(() => {
            const typology = updateAddress.typology ? ` ${updateAddress.typology}` : ''
            let address = typology + updateAddress.address
            let number = updateAddress.number
            let complement = updateAddress.complement
            let neighborhood = updateAddress.neighborhood
            let city = updateAddress.city
            let uf = updateAddress.uf
            let zipCode = updateAddress.zipCode

            this.dataItems = Object.assign({}, this.dataItems, { address, number, complement, neighborhood, city, uf, zipCode })
          })
        } else if (typeof updateAddress === 'string'){
          this.$nextTick(() => {
            this.dataItems = Object.assign({}, this.dataItems, { zipCode: updateAddress })
          })
        }
      },
      _resetAllDataAddress () {
        this.listCeps = []
        this._resetAddress()
      },
      setListCeps () {
        if (this.hasInvestigator){
          this.listCeps = this.investigator
        } else {
          this.listCeps = [this.dataItems]
        }
      }
    }
  }
</script>
