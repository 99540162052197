<template>
  <v-combobox
    ref="select"
    v-model="selectedItem"
    item-text="zipCode"
    label="CEP"
    :items="items"
    :disabled="disabled"
    :rules="cepRules"
    :menu-props="{contentClass: dataCy + ' small-chips'}"
    :data-cy="dataCy"
    deletable-chips
    small-chips
    @input="inputItem"
    @blur="blurEvent">
    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item.zipCode" />
      </template>
      <template>
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.zipCode }} - {{ data.item.typology }} {{ data.item.address }}
            <v-chip
              v-if="data.item.isMain"
              label
              color="darkGray"
              text-color="white"
              small
              class="chip">
              principal
            </v-chip>
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-combobox>
</template>

<script>
  import validate from '@/mixins/validate'

  export default {
    mixins: [validate],
    props: {
      items: {
        type: Array,
        default: () => ([]),
        required: true
      },
      dataCy: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      empty: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        selectedItem: '',
        listCeps: []
      }
    },
    watch: {
      empty (isEmpty) {
        isEmpty && this.resetField()
      },
      selectedItem (cep) {
        !cep || cep.length === 0 && this.emitEmptyStatus()
      }
    },
    mounted () {
      this.$refs.select.$el.getElementsByTagName('input')[0].setAttribute('autocomplete', 'disabled')
    },
    methods: {
      inputItem (item) {
        if (item && typeof (item[0]) === 'object' && item.length > 0) {
          this.replaceItem(item)
          this.$emit('change', this.selectedItem)

          return
        }

        this.resetField()
        this.selectedItem = item
        this.$emit('change', [this.selectedItem])
      },
      replaceItem (item) {
        this.$refs.select.blur()
        if (item.length > 1) {
          this.selectedItem = item.slice(-1)
        } else {
          this.selectedItem = item
        }
      },
      blurEvent () {
        this.$emit('blur')
      },
      resetField () {
        this.selectedItem = []
      },
      emitEmptyStatus () {
        this.$emit('empty')
      }
    }
  }
</script>
